import Vue from 'vue'
import './set-public-path'
import App from './App.vue'
import VueI18n from "vue-i18n";
import axios from 'axios'
import router from "./router";
import commonMixin from '../src/assets/common/vue-common.js'
import ListingcommonMixin from '../src/assets/common/vue-commonList.js'
import ModuleHeader from '../src/components/Header/ModuleHeader.vue';
import breadcrumb from './components/Common/BreadCrumb.vue'
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useloggedUserInfo } from '../src/stores/UserInfo';
import { DynamicForm } from 'tg-controls_cli/dist/myLib.common'
import {TgList} from 'tg-listv/dist/tglistv.common'
//import VueCompositionAPI from '@vue/composition-api'
import singleSpaVue from 'single-spa-vue';
import Loader from './components/Common/Loader.vue'
import vuescroll from 'vuescroll';
const resourceFilePath = process.env.VUE_APP_LANGUAGE_FILE_URL;
const envType = process.env.VUE_APP_NODE_ENV;
 var $ = require('jquery');
Vue.config.productionTip = false;
Vue.prototype.$= $;
require('./vee-validate');
Vue.use(VueI18n);
Vue.use(PiniaVuePlugin);
Vue.component('loader', Loader)
Vue.component('dynamic-form',DynamicForm); 
Vue.component('tg-list',TgList);
Vue.component('breadcrumb', breadcrumb);
Vue.component('ModuleHeader',ModuleHeader);
Vue.use(vuescroll); 
const pinia=createPinia();
window.pinia = pinia;
Vue.use(pinia);
let parsedLocalStorage = localStorage.getItem('TALGYEN_NAVBAR_SETTING');
let selectedLang = "en"; // Default to English
  
if(parsedLocalStorage) {
  const settings = JSON.parse(parsedLocalStorage);
  selectedLang = settings.lang.toLowerCase() || "en"; // Use stored language or default to English
}
const i18n = new VueI18n({
  locale: selectedLang,
  messages: {
  },
});
// const i18n = new VueI18n({
//   locale: "en",
//   messages: {
//     en:EN,
//   },
// });
Vue.mixin(commonMixin, ListingcommonMixin);

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  // document.querySelector('html').setAttribute('lang', lang)
  // return lang
}

Vue.filter('formatDatee', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('YYYY-MM-DD'));
}
})
async function loadLanguageAsync (lang) {
  if (loadedLanguages.includes(lang)) {
    if (i18n.locale !== lang) setI18nLanguage(lang)
    return Promise.resolve()
  }

  await axios.get(`${resourceFilePath}/${envType}/${lang}.js`).then(response => {
    let msgs = response.data
    loadedLanguages.push(lang)
    i18n.setLocaleMessage(lang, response.data[0])
  })
}

// router.js
router.beforeEach((to, from, next) => {
  loadLanguageAsync(selectedLang).then(() => next());
})
Vue.filter('formatDateYear', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('YYYY-MM-DD'));
}
})
Vue.filter('formatDate', function (value){
  if (value) {
    return moment(value, "YYYY-MM-DD").format(String('MM/DD/YYYY'));
}
})
const containerSelector = '#page-2-container'
const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    pinia,
    i18n,
    router,
    render(h) {     
      return h(App, {
         props: {          
          props: {          
            userProfile:useloggedUserInfo().setUser(this.userProfile) 
           } 
        } 
      });//
    },
    el: containerSelector
    
  }
});
export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
